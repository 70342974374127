import { computed, defineComponent } from "vue";

import helper from "@/app/utils/helper";

import TableGridTh from "./table-grid-th/TableGridTh.vue";
import TableGridTr from "./table-grid-tr/TableGridTr.vue";
import TableGridFt from "./table-grid-ft/TableGridFt.vue";
import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import TgThAllCheck from "./tg-th-all-check/TgThAllCheck.vue";

export default defineComponent({
	name: 'table-grid',
	components: {
		TableGridTr, TableGridTh, TableGridFt,
		BsCheckbox, TgThAllCheck,
	},
	props: {
		columns: {
			type: Array,
			default: ()=>[],
		},
		rows: {
			type: Array,
			default: ()=>[],
		},
		indexRowId: {
			type: String,
			default: 'id',
		},
		keyRowGroup: {
			type: String,
			default: '',
		},
		isSelected: {
			type: Boolean,
			default: false,
		},
		rowHeadClassName: {
			type: [String, Function],
			default: '',
		},
		rowClassName: {
			type: [String, Function],
			default: '',
		},
		rowFtClassName: {
			type: [String, Function],
			default: '',
		},


		checkedAllPage: Boolean, // Включает настройку возможности выбора всех страниц для массовых действий
		allCheckSettingsValue: {
			type: String,
			default: 'check-one-page',
		},
	},
	emits: [
		'changeSelectedRows', 'dblClickRow',
		'changeAllCheckSettings', 'update:allCheckSettingsValue',
	],
	data() {
		return {
			selectedRows: new Set(),
			scrollTop: 0,
			scrollLeft: 0,
			colFixPosition: {}
		}
	},
	provide() {
		return {
			scrollTop: this.scrollTop,
			scrollLeft: this.scrollLeft,
			colFixPosition: computed(()=>this.colFixPosition),
		}
	},
	computed: {
		modelAllCheckSettingsValue:{
			get(){
				return this.allCheckSettingsValue;
			},
			set(val){
				this.$emit('update:allCheckSettingsValue', val);
			},
		},
		isAllCheckRow(){
			const countSelected = this.selectedRows.size;
			const countTotal = this.rows.length;

			return !!countSelected && (countSelected == countTotal);
		},
		isIndeterminateAll(){
			const countSelected = this.selectedRows.size;
			const countTotal = this.rows.length;

			if( !!countSelected && (countSelected != countTotal) ) return true;
			return false;
		},
		columnMap(){
			const map = {};
			for(const item of this.columns){
				map[item.key] = item;
			}
			return map;
		},
		countFixedHide(){
			return this.columns.filter(item=>item?.fixed && !item?.show).length;
		},
		isColumnsGrouping(){
			const findItemGrouping = this.columns.find(item=>item?.group?.length);
			return !!findItemGrouping;
		},
		theadGroupColumns(){
			const columns = [];
			if(this.isColumnsGrouping) for(const item of this.columns){
				if(!item?.group?.length) continue;
				if(item?.show) columns.push(...item.group);
			}
			return columns;
		},
		tbodyColumns(){
			const columns = [];

			for(const item of this.columns){
				if(item?.group?.length && item?.show) columns.push(...item.group);
				else columns.push(item);
			}

			return columns;
		}
	},
	methods: {
		getIndexRow(row){
			if(this.keyRowGroup && row?.[this.keyRowGroup]?.[this.indexRowId]){
				return row[this.keyRowGroup][this.indexRowId];
			}
			else if(row?.[this.indexRowId]) return row[this.indexRowId];
			else return helper.hash;
		},
		checkRow({is, rowData}){
			const { id } = rowData?.orderData ?? {};
			if(is) this.selectedRows.add(rowData);
			else this.selectedRows.delete(rowData);

			this.$emit('changeSelectedRows', [...this.selectedRows]);
		},
		changeAllCheck(is){
			console.log(is);
			if(is) for( const item of this.rows ){
				this.selectedRows.add( item );
			}
			else this.selectedRows.clear();

			this.$emit('changeSelectedRows', [...this.selectedRows]);
		},
		getColSpanSize(col){
			let size = 1;
			if(col?.group?.length){
				const filterShowColumns = col.group.filter(item=>item.show);
				size = filterShowColumns.length;
			}
			return size || 1;
		},
		handleScroll(e){
			const { scrollTop, scrollLeft } = e.target;
			this.scrollTop = scrollTop;
			this.scrollLeft = scrollLeft;
		},
		calcColFixPosition(){
			const { refTrHead } = this.$refs;
			const nodeListLeft = refTrHead.querySelectorAll('.th-fixed-left');
			const nodeListRight = refTrHead.querySelectorAll('.th-fixed-right');
			const colLeftMap = {};
			const colRightMap = {};
			let totalPosLeft = 0;
			let totalPosRight = 0;
			let colLeftEnd = '';
			let colRightEnd = '';

			// console.log(nodeListLeft);
			// offsetLeft, offsetWidth, dataSet

			for( const item of nodeListLeft ){
				const { offsetLeft, offsetWidth, dataset } = item;
				const { colKey } = dataset;
				const col = this.columnMap?.[colKey];
				// console.log(colKey, { offsetLeft, offsetWidth, item });

				if(colKey=='check'){
					totalPosLeft += offsetWidth-1;
					colLeftEnd = colKey;
					continue;
				}
				else if(!col?.show) continue;

				colLeftMap[colKey] = totalPosLeft;
				totalPosLeft += offsetWidth;
				colLeftEnd = colKey;
			}

			for( const item of nodeListRight ){
				const { offsetLeft, offsetWidth, dataset } = item;
				const { colKey } = dataset;
				const col = this.columnMap?.[colKey];
				// console.log(colKey, { offsetLeft, offsetWidth });

				if(colKey=='check'){
					totalPosRight += offsetWidth-1;
					if(!colRightEnd) colRightEnd = colKey;
					continue;
				}
				else if(!col?.show) continue;

				colRightMap[colKey] = totalPosRight;
				totalPosRight += offsetWidth;
				if(!colRightEnd) colRightEnd = colKey;
			}

			// console.log({colLeftMap, colRightMap});
			this.colFixPosition = {
				left: colLeftMap,
				right: colRightMap,
				colLeftEnd, colRightEnd,
			};
		},
		dblClickRow(rowData){
			this.$emit('dblClickRow', rowData);
		},
		clearSelection(){
			this.selectedRows.clear();
		},
		changeAllCheckSettings(val){
			if(val=='check-all-page') this.changeAllCheck(true);
			this.$emit('changeAllCheckSettings', val);
		}
	},
	watch:{
		async countFixedHide(val){
			await this.$nextTick();
			this.calcColFixPosition();
		},
		async columns(list){
			await this.$nextTick();
			this.calcColFixPosition();
		},
		rows(){
			this.clearSelection();
		},
		isAllCheckRow(is){
			if(!is){
				const { refAllCheck } = this.$refs;
				if(refAllCheck) refAllCheck.resetSettings();
			}
		}
	},
	mounted(){
		this.calcColFixPosition();
	}
});