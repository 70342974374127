import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

export const useNotifStore = defineStore('notifStore', {
	state() {
		return {
			isViewLoader: false,
			unreadCount: 0,

			notifData: [],

			tableData: [
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: new Date(),
					"notificationTime": "22-01-2025 14:55:15",
					senderType: 'user',
					notifType: 'agreement',
					"notification": {
						"body": "Файл с экспортом АП 'ФРА: Скайтек/Ozon (2024)' можно скачать",
						"actions": {
							"ef": {
								"api": "https://outplan-dev.devtestprod.ru/api/target-program/84/export-manager-excel/file?name=1737557715-04be8dda46d35333ad0f052cdbfa246b",
								"text": "Скачать файл",
								"type": "target_program_exported_file_ready",
								"color": "#5229A1",
								"method": "GET",
								"params": []
							},
							"ef2": {
								"api": "https://outplan-dev.devtestprod.ru/api/target-program/84/export-manager-excel/file?name=1737557715-04be8dda46d35333ad0f052cdbfa246b",
								"text": "Согласовать",
								"type": "approve",
								"color": "#5229A1",
								"method": "GET",
								"params": []
							},
							"ef3": {
								"api": "https://outplan-dev.devtestprod.ru/api/target-program/84/export-manager-excel/file?name=1737557715-04be8dda46d35333ad0f052cdbfa246b",
								"text": "Отказать",
								"type": "deny",
								"color": "#5229A1",
								"method": "GET",
								"params": []
							}
						},
						"targetProgram": {
							"id": "84",
							"url": "https://outplan-dev.devtestprod.ru/target-programs/84",
							"name": "ФРА: Скайтек/Ozon (2024)"
						},
						"notificationTime": "22-01-2025 14:55:15",
						"linesInTargetProgram": 0,
						"initiator": {
							"name": "Система",
							"email": "",
							"tg_id": "",
							"last_name": "",
							"middle_name": null,
							"phone": "",
							"photo": "/special-avatar.jpg"
						}
					},
					isRead: false,
					message: 'Все поверхности работают. <br> Можно проверить в личном кабинете.',
					user: {
						avatar: '/assets/img/res/bs-avatar/demo-avatar.jpg',
						name: 'Терешков Павел',
						firstName: 'Павел',
						lastName: 'Терешков',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)999-99-99',
							telegram: '@testTest',
						},
					}
				},
				{
					"id": 15,
					"notificationId": 15,
					"notificationType": "tp_row_off_changed_needs_approval",
					"notification": {
						"body": "Файл с экспортом АП 'ФРА: Скайтек/Ozon (2024)' можно скачать",
						"actions": {
							"ef": {
								"api": "https://outplan-dev.devtestprod.ru/api/target-program/84/export-manager-excel/file?name=1737557715-04be8dda46d35333ad0f052cdbfa246b",
								"text": "Скачать файл",
								"type": "target_program_exported_file_ready",
								"color": "#5229A1",
								"method": "GET",
								"params": []
							}
						},
						"targetProgram": {
							"id": "84",
							"url": "https://outplan-dev.devtestprod.ru/target-programs/84",
							"name": "ФРА: Скайтек/Ozon (2024)"
						},
						"notificationTime": "22-01-2025 14:55:15",
						"linesInTargetProgram": 0,
						"initiator": {
							"name": "Система",
							"email": "",
							"tg_id": "",
							"last_name": "",
							"middle_name": null,
							"phone": "",
							"photo": "/special-avatar.jpg"
						}
					}
				}
			],
		}
	},

	getters: { },
	actions: {
		updateUnreadCount(count){
			this.unreadCount = +count;
		},

		setNotifData(newData){
			this.notifData = newData
		},

		async getUnreadMessages(){
			try {
				const res = await api.getData({
					model: `notifications-center/unread`,
				});
				this.notifData = res;
				return res
			} catch (error) {
				console.error('getUnreadMessages error: ', error)
			}
			return;
		},

		async getUnreadCount() {
			try {
				console.log('getUnreadCount start')
				const res = await api.getData({ model: 'notifications-center/unread-count' });
				this.updateUnreadCount(res.count);
				console.log('getUnreadCount result', res, this.unreadCount)
			} catch (error) {
				console.error('getUnreadCount error:', error);
			}
		}
	},
});