import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bs-header-auth__left" }
const _hoisted_2 = { class: "bs-header-auth__logo" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeadLogo = _resolveComponent("MainHeadLogo")
  const _component_ElHeader = _resolveComponent("ElHeader")

  return (_openBlock(), _createBlock(_component_ElHeader, { class: "bs-header-auth" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MainHeadLogo)
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bs-header-auth__middle" }, null, -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bs-header-auth__right" }, null, -1 /* HOISTED */))
    ]),
    _: 1 /* STABLE */
  }))
}