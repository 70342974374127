import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-layout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsHeaderAuth = _resolveComponent("BsHeaderAuth")
  const _component_BsMain = _resolveComponent("BsMain")
  const _component_ElContainer = _resolveComponent("ElContainer")
  const _component_FooterAuth = _resolveComponent("FooterAuth")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsHeaderAuth),
    _createVNode(_component_ElContainer, { class: "auth-layout__container" }, {
      default: _withCtx(() => [
        _createVNode(_component_BsMain, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        })
      ]),
      _: 3 /* FORWARDED */
    }),
    _createVNode(_component_FooterAuth)
  ]))
}