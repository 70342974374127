import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "main-head-logo",
    to: "/"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "main-head-logo__title" }, "АУТПЛАН", -1 /* HOISTED */),
      _createElementVNode("img", {
        class: "main-head-logo__img",
        src: "/assets/img/res/main-head-logo.svg",
        alt: "main-logo-rim"
      }, null, -1 /* HOISTED */)
    ])),
    _: 1 /* STABLE */
  }))
}