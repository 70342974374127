import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';

import { ElBreadcrumb, ElBreadcrumbItem } from 'element-plus';
import 'element-plus/es/components/breadcrumb/style/css';
import 'element-plus/es/components/breadcrumb-item/style/css';

import { routes } from '@/app/router/routes';
import helper from '@/app/utils/helper';

import SeparatorIcon from '@/app/components/bs-breadcrumb/SeparatorIcon.vue';

export default {
	name: 'bs-breadcrumb',
	components:{
		ElBreadcrumb, ElBreadcrumbItem,
	},
	computed: {
		...mapState(useRootStore, ['pageData']),

		crumbsList() {
			const list = [];
			let route = this.$route;

			while(true){
				const { meta, path } = route ?? {};
				const { breadcrumb } = meta ?? {};
				let title = breadcrumb?.title ?? '';

				// Не вывожу в АП название, т.к. сказали убрать
				if(breadcrumb?.isDynamicTitle && !breadcrumb?.parent === "target-programs") title = this.pageData?.title ?? '';

				list.push({
					to: path,
					title: helper.upFirstLetter(title),
				});

				if(breadcrumb?.parent){
					const findRoute = routes.find(item=>item.name==breadcrumb.parent);
					route = findRoute;
					if(!findRoute) break;
				} else break;
			}

			return list.reverse();
		}
	},
	watch:{
		// crumbsList(data){
		// 	console.log(data);
		// }
	},
	setup(){
		return { SeparatorIcon }
	}
}