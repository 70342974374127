import { defineComponent } from "vue";
import dayjs from "dayjs";
import AirDatepicker from 'air-datepicker';

import helper from "@/app/utils/helper";

import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';

export default defineComponent({
	name: 'employ-datepicker',
	components: {
		BsCheckbox,
	},
	props:{
		date: {
			type: [ String, Date ],
			default: dayjs(new Date()).format('YYYY-MM-DD'),
		},
		calendar: {
			type: Object,
			default: ()=>({}),
		},
		selectedPeriods: {
			type: Object,
			default: ()=>({}),
		},
	},
	emits: ['update:selectedPeriods'],
	data() {
		return {
			datepicker: null,
			selectedDates: [],
		}
	},
	computed: {
		minDate(){
			return dayjs(this.date).date(1).format('YYYY-MM-DD');
		},
		maxDate(){
			const countDay = dayjs(this.date).daysInMonth();
			return dayjs(this.date).date(countDay).format('YYYY-MM-DD');
		},
		periods(){
			return helper.convertDatesToPeriods(this.selectedDates);
		},
		datesFromPeriods(){
			const result = [];
			const curDateMonth = dayjs(this.date).format('YYYY-MM');
			const periods = this.selectedPeriods?.[curDateMonth];
			console.log(periods);

			if(periods) for(let period of periods){
				const startDate = new Date(period[0]);
				const endDate = new Date(period[1]);

				let currentDate = startDate;

				while (currentDate <= endDate) {
					result.push(new Date(currentDate));
					currentDate.setDate(currentDate.getDate() + 1);
				}
			}

			// console.log(result);
			return result;
		},
		isAllMonthSelected(){
			const curDateMonth = dayjs(this.date).format('YYYY-MM');
			const periods = this.selectedPeriods?.[curDateMonth];
			const countMonthDays = dayjs(curDateMonth).daysInMonth();
			const firstDay = dayjs(curDateMonth).date(1).format('YYYY-MM-DD');
			const lastDay = dayjs(curDateMonth).date(countMonthDays).format('YYYY-MM-DD');
			let is = false;

			if(periods?.length == 1){
				const [from , to] = periods[0];
				is = (from==firstDay && to==lastDay);
			}
			// console.log('isAllMonthSelected', periods, {firstDay, lastDay}, is);
			return is;
		}
	},
	methods:{
		initCalendar(){
			const { calendar } = this.$refs;
			const datepicker = new AirDatepicker(calendar, {
				inline: true,
				multipleDates: true,
				startDate: this.minDate,
				minDate: this.minDate,
				maxDate: this.maxDate,
				selectedDates: this.datesFromPeriods,

				onRenderCell: (data)=>{
					const { date } = data;

					return {
						classes: this.getClassStatus(date),
					};
				},
				onSelect: (data)=>{
					// console.log(data);
					const { date } = data;
					this.selectedDates = date;
				}
			})
			this.datepicker = datepicker;
		},
		getClassStatus(date){
			const formatDate = dayjs(date).format('YYYY-MM-DD');
			const dayDate = this.calendar?.[formatDate];

			if(dayDate?.status) return `type-${dayDate?.status}`;
			return 'type-free';
		},
		onAllChecked(is){
			// console.log(is);
			const countDay = dayjs(this.date).daysInMonth();
			const selectedDates = [];

			if(is) for(let i=1; i<=countDay; i++){
				let curDay = dayjs(this.minDate).date(i).toDate();
				selectedDates.push(curDay);
			}

			this.datepicker.update({ selectedDates });
			if(!selectedDates?.length) this.selectedDates = selectedDates;
		}
	},
	watch: {
		async date(value){
			// console.log(value);
			this.datepicker.destroy();
			await this.$nextTick();
			this.initCalendar();
		},
		periods(data){
			helper.debounce({
				keyTimer: 'employ-datepicker-period-update',
				action: ()=>{
					const curDateMonth = dayjs(this.date).format('YYYY-MM');
					const selectedPeriods = {
						...this.selectedPeriods,
					}

					selectedPeriods[curDateMonth] = data;
					console.log(selectedPeriods);
					this.$emit('update:selectedPeriods', selectedPeriods);
				}
			});
		},
	},
	created() {
		console.log(this.date);
		// console.log('selectedPeriods', this.selectedPeriods);
	},
	mounted() {
		this.initCalendar();
	},
	unmounted() {
		this.datepicker.destroy();
	},
});