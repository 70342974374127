import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "employ-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EmployInfoStatic = _resolveComponent("EmployInfoStatic")
  const _component_EmployInfoDigital = _resolveComponent("EmployInfoDigital")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type=='static')
      ? (_openBlock(), _createBlock(_component_EmployInfoStatic, {
          key: 0,
          isDblMode: _ctx.isDblMode,
          period: _ctx.period,
          data: _ctx.data,
          sideData: _ctx.sideData,
          isShowCalendar: _ctx.isShowCalendar,
          "onUpdate:checkMonthDate": _ctx.changeMonth
        }, null, 8 /* PROPS */, ["isDblMode", "period", "data", "sideData", "isShowCalendar", "onUpdate:checkMonthDate"]))
      : _createCommentVNode("v-if", true),
    (_ctx.type=='digital')
      ? (_openBlock(), _createBlock(_component_EmployInfoDigital, {
          key: 1,
          isDblMode: _ctx.isDblMode,
          period: _ctx.period,
          data: _ctx.data,
          duration: _ctx.duration,
          frequency: _ctx.frequency,
          sideData: _ctx.sideData,
          digitalData: _ctx.sideData.digitalData,
          isShowCalendar: _ctx.isShowCalendar,
          "onUpdate:checkMonthDate": _ctx.changeMonth
        }, null, 8 /* PROPS */, ["isDblMode", "period", "data", "duration", "frequency", "sideData", "digitalData", "isShowCalendar", "onUpdate:checkMonthDate"]))
      : _createCommentVNode("v-if", true)
  ]))
}