import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import api from '@/app/utils/service-api';
import helper from '@/app//utils/helper';

export const useFiltersStore = defineStore('filtersStore', {
	state() {
		return {
			filtersList: [
				{
					key: 'city',
					slug: 'cities',
					isLoad: false,
					options: [],
				},
				{
					key: 'targetProgram',
					slug: 'target-program',
					isLoad: false,
					options: [],
					// remote: true,
				},
				{
					key: 'direction',
					slug: 'direction',
					isLoad: false,
					options: [],
				},
				{
					key: 'structureType',
					slug: 'structure-types',
					isLoad: false,
					options: [],
				},
				{
					key: 'size',
					slug: 'size',
					isLoad: false,
					options: [],
				},
				{
					key: 'mechanism',
					slug: 'mechanism',
					isLoad: false,
					options: [],
				},
				{
					key: 'lighted',
					slug: '',
					isLoad: false,
					options: [
						{
							value: '1',
							label: 'Да',
						},
						{
							value: '0',
							label: 'Нет',
						},
					],
				},
				{
					key: 'freedom',
					slug: 'freedom',
					isLoad: false,
					options: [],
				},
				{
					key: 'bookingStatus',
					slug: 'booking-statuses',
					isLoad: false,
					options: [],
				},
			],
			batchFilterData: null,
		}
	},
	getters: {
		filtersMap(){
			const map = {};
			for( const item of this.filtersList ){
				const { key } = item;
				map[key] = item;
			}

			return map;
		},
	},
	actions: {
		async getFilterOptions({key, query}={}){
			const filterItem = this.filtersMap?.[key];
			if(!filterItem) return;
			const { slug } = filterItem;
			let params = {};

			if(query) params['query'] = query;
			filterItem.isLoad = true;

			try {
				const res = await api.getData({
					model: `filter/${slug}`,
					params: { ...params },
				});

				if(res?.length){
					filterItem.options = res;
				} else filterItem.options = [];

				// console.log(res);
			} catch (error) {
				console.error(error);
			}

			filterItem.isLoad = false;
			return;
		},
		async loadEnumOptions(){
			const promiseList = [];

			for( const filterItem of this.filtersList ){
				if(filterItem?.remote) continue;
				const { key, slug } = filterItem;

				if(!slug) continue;
				const prom = this.getFilterOptions({ key });

				promiseList.push(prom);
			}

			return await Promise.allSettled(promiseList);
		},
		getOptions(key){
			// console.log(key);
			let options = [];
			if(this.batchFilterData?.[key]?.length) options = this.batchFilterData[key];
			return options;
		},
		async getBatchFiltersData(){
			if(this.batchFilterData) return;

			const lighting = [
				{
					value: 1,
					label: 'Да'
				},
				{
					value: 0,
					label: 'Нет'
				},
			];
			const wifiTrap = [
				{
					value: 1,
					label: 'есть'
				},
				{
					value: 0,
					label: 'нет'
				},
			];
			const fragmentingPeriod = [
				{
					value: 1,
					label: 'Да',
				},
				{
					value: 0,
					label: 'Нет',
				}
			];
			const currentMonth = [
				{
					value: 1,
					label: 'Текущий',
				},
				{
					value: 0,
					label: 'Обычный',
				}
			];
			const compare = [
				// {
				// 	label: '=',
				// 	value: '='
				// },
				{
					label: '<',
					value: '<'
				},
				{
					label: '>',
					value: '>'
				},
			];

			try {
				let defaultBatchData = await Promise.allSettled([
					api.getData({ model: 'filters' }),
					api.getData({ model: 'target-program/create-options' }),
				]);
				defaultBatchData = defaultBatchData.map(item=>item?.value ?? []);

				// console.time('options');
				const filterBatchData = await Promise.allSettled([
					api.getData({ model: 'filter/net' }),
					api.postData({ model: 'filter/managers' }),
					api.getData({ model: 'filter/express-printers' }),
					api.getData({ model: 'filter/structure-categories' }),
					api.getData({ model: 'filter/price-policy-statuses' }),
					api.getData({ model: 'filter/price-policy-types' }),
					api.getData({ model: 'filter/user-roles' }),
					api.getData({ model: 'filter/rim-or-partner-legal-entities' }),
					api.getData({ model: 'filter/approved-types' }),
				]);
				// console.timeEnd('options');
				const [
					net,
					managers, expressPrinters, structureCategories,
					pricePolicyStatuses, pricePolicyTypes,
					userRoles, rimOrPartnerLegalEntities, approvedTypes,
				] = filterBatchData.map(item=>item?.value ?? []);


				const optionsData = {
					lighting, wifiTrap, fragmentingPeriod, currentMonth, compare,
					net,
					managers, expressPrinters, structureCategories, rimOrPartnerLegalEntities,
					pricePolicyStatuses, pricePolicyTypes, approvedTypes,
					userRoles,
				};

				for( const res of defaultBatchData ){
					if(res?.data) this.batchFilterData = {
						...this.batchFilterData,
						...res.data
					}
				}

				for( const [key, options] of Object.entries(optionsData) ){
					this.batchFilterData = {
						...this.batchFilterData,
						[key]: options?.length? options: [],
					}
				}

				console.log('getBatchFiltersData', this.batchFilterData);
				return this.batchFilterData;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async getManagers({ name, departments }){
			let filters = {};

			if(name) filters['name'] = name;
			if(departments?.length) filters['managersByDepartments'] = departments;

			try {
				const res = await api.postData({
					model: 'filter/managers',
					data:{
						filters,
					},
				});

				console.log('getManagers', res);
				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		},
	},
});