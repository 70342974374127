import { defineComponent } from 'vue';

import dayjs from 'dayjs';
import helper from '@/app/utils/helper';
import employInfoMixin from '@/app/mixins/employ-info-mixin';

import SurfaceMonthItem from '@/app/components/map/map-design-info/employ-info/employ-info-digital/surface-month-item/SurfaceMonthItem.vue';

export default defineComponent({
	name: 'employ-info-digital',
	components: {
		SurfaceMonthItem,
	},
	mixins: [ employInfoMixin ],
	props: {
		data: Object,
		period: {
			type: Object,
			default: ()=>({}),
		},
		duration: {
			type: Number,
			default: 5,
		},
		frequency: {
			type: Number,
			default: 1,
		},
		sideData: Object,
		digitalData: Object,
		isShowCalendar: Boolean,
		// checkMonthDate: String,
	},
	emits: ['update:checkMonthDate'],
	data() {
		return {
			monthElementData: {},
			selectedMonth: '',
		}
	},
	computed: {
		occupancy() {
			const { digitalOccupancy } = this.data;
			const { blockDuration, slotDuration } = this.digitalData;
			const { duration, frequency } = this;
			let occupancy = {};

			if(this.$route?.query?.view != 'grid'){
				// console.log({ duration, frequency });
				// console.log('occupancy', digitalOccupancy);
			}

			// если приходит пустой градусник или в виде пустого массива
			if (!digitalOccupancy || (Array.isArray(digitalOccupancy) && !digitalOccupancy?.length)) {
				// console.log('inside', this.monthList);
				for (let item of this.monthList) {
					const { date } = item;
					occupancy[date] = {
						data: {
							status: 'free',
							value: 0,
						},
						calendar: {},
						added: this.isCheckPeriod(date),
					};
				}
			}
			// console.log('occupancy2', digitalOccupancy)
			if(digitalOccupancy) {
				for (let [key, item] of Object.entries(digitalOccupancy)) {
					const { free, reserved, busy, unavailable, calendar, status } = item;
					const countOutHour = 60*60 / blockDuration; // Количество выходов в час стандартного хроно, равного слоту

					const timeFree = free * slotDuration; // свободно в часе
					const valueFree = Math.ceil(timeFree / duration); // значение в ячейке

					const timeReserved = reserved * slotDuration; // зарезервировано в часе
					const valueReserved = Math.ceil(timeReserved / duration); // значение в ячейке

					const totalTime = timeFree + timeReserved;

					const personTime = Math.ceil(duration * (frequency * countOutHour)); // Сколько нужно пользователю


					const added = this.isCheckPeriod(key);
					let calendarData = {};
					let data = {};

					// console.log(item);
					// console.log(personTime);

					// (block/slot) * 60 должно быть меньше чем busy, либо можно посмотреть на "status": "busy" - тогда точно всё занято
					if(status=='dismounted') {
						// демонтировано белый
						data = {
							status: 'dismounted',
							value: 0
						}
					}
					else if(unavailable || status=='busy') {
						// недоступно красный
						data = {
							status: 'busy',
							value: 0
						}
					}
					else if (free && timeFree >= personTime) {
						// зеленый
						data = {
							status: 'free',
							value: valueFree,
							isFract: valueFree < 60,
						}
					}
					else if (free && (timeFree < personTime) && totalTime >= personTime) {
						// двойная зеленый/желтый
						data = {
							chunks: [
								{
									status: 'free',
									value: valueFree
								},
								{
									status: 'reserved',
									value: valueReserved
								},
							]
						}
					}
					else if (reserved && timeReserved >= personTime) {
						// желтый
						data = {
							status: 'reserved',
							value: valueReserved,
							isFract: valueReserved < 60,
						}
					}
					else {
						// красный
						data = {
							status: 'busy',
							value: 0
						}
					}

					if (calendar) for (let [key, item] of Object.entries(calendar)) {
						let status = '';

						if (free && timeFree >= personTime) {
							// зеленый
							status = 'free';
						} else if (reserved && timeReserved >= personTime) {
							// желтый
							status = 'reserved';
						}
						else {
							// красный
							status = 'busy';
						}

						calendarData[key] = { status };
					}

					occupancy[key] = {
						...data,
						added,
						calendar: calendarData,
					};
				}
			}

			// if(this.$route?.query?.view != 'grid'){
			// 	console.log('occupancy', occupancy);
			// }
			return occupancy;
		}
	},
	methods: {
		getMonthItemData(monthData) {
			if (!this.occupancy) return;

			const data = this.occupancy?.[monthData.date];
			if (!data) return;

			return data;
		},
		isTargetMonthAdded(monthData) {
			const data = this.getMonthItemData(monthData);
			return data?.inTargetProgram;
		},
		getTypeItem(monthData) {
			const data = this.getMonthItemData(monthData);

			if (data?.chunks) return 'multi';
			else return 'single';
		},
		clickMonth(date) {
			if (!this.isShowCalendar && this.selectedMonth == date) this.selectedMonth = '';
			else this.selectedMonth = date;

			this.$emit('update:checkMonthDate', this.selectedMonth);
		},
		isCheckPeriod(date) {
			const { selectedPeriods } = this.sideData;
			const monthDate = dayjs(date).format('YYYY-MM');

			if (selectedPeriods?.[monthDate]?.length) return true;
			// else {
			// 	const { from, to } = this.compPeriod;
			// 	const fromDate = dayjs(from).format('YYYY-MM');
			// 	const toDate = dayjs(to).subtract(1, 'days').format('YYYY-MM');
			// 	// console.log(fromDate, toDate);
			// 	if( [fromDate, toDate].includes(monthDate) ) return true;
			// }

			return false;
		},
	},
	watch: {
		async period() {
			await this.$nextTick();
			helper.pause(100, () => {
				this.calcMonthElementPosition();
			});
		},
		checkMonthDate(val) {
			this.selectedMonth = val;
		},
		async sideData(val){
			// this.selectedMonth = this.monthList[0]?.date ?? '';
		}
		// ['sideData.selectedPeriods'](data){
		// 	console.log(data);
		// }
	},
	async mounted() {
		await this.$nextTick();
		helper.pause(100, () => {
			this.calcMonthElementPosition();
		});
	},
	created() {
		if (this.isShowCalendar) {
			const { from, to } = this.compPeriod;
			this.selectedMonth = dayjs(from).format('YYYY-MM');
		}
		this.$emit('update:checkMonthDate', this.selectedMonth);
	},
});