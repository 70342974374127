import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "notif-message-system__head" }
const _hoisted_2 = { class: "notif-message-system__info" }
const _hoisted_3 = { class: "notif-message-system__date" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "notif-message-system__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NotifSystemInfo = _resolveComponent("NotifSystemInfo")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notif-message-system", { '--is-message-not-read': !_ctx.data?.isRead }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NotifSystemInfo)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.date), 1 /* TEXT */)
    ]),
    _createElementVNode("div", {
      class: "notif-message-system__message",
      innerHTML: _ctx.data?.notification?.body
    }, null, 8 /* PROPS */, _hoisted_4),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data?.notification?.actions, (item) => {
        return (_openBlock(), _createElementBlock("div", { key: item }, [
          (item?.type === 'approve')
            ? (_openBlock(), _createBlock(_component_BsBtn, {
                key: 0,
                type: "primary",
                style: _normalizeStyle(_ctx.getButtonStyles(item?.color)),
                size: "small",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.showApprovePopup('approve')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style"]))
            : (item?.type === 'deny')
              ? (_openBlock(), _createBlock(_component_BsBtn, {
                  key: 1,
                  style: _normalizeStyle(_ctx.getButtonStyles(item?.color)),
                  size: "small",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.showApprovePopup('deny')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style"]))
              : (_openBlock(), _createBlock(_component_BsBtn, {
                  key: 2,
                  style: _normalizeStyle(_ctx.getButtonStyles(item?.color)),
                  size: "small",
                  onClick: $event => (_ctx.redirect(item?.api))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style", "onClick"]))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}