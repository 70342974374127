import { defineStore } from 'pinia';
import { useDesignFilterStore } from './design-filter-store';
import { useMapDesignStore } from './map-design-store';

import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';
import errorService from '@/app//utils/error-service';

import { colConfigList } from '../../components/grid/grid-designs-table/table-config';

export const useGridDesignStore = defineStore('gridDesignStore', {
	state() {
		return {
			isLoaderGrid: true,
			// isLoaderGrid: false,

			gridTableData: [],
			gridPaginationData: null,
			colConfigList,
			paramGridData: null,

			selectTableData: [],

			photoList: [
				{
					id: helper.hash,
					src: '/assets/img/res/map-design-info/image-1.jpg',
				},
				{
					id: helper.hash,
					src: '/assets/img/res/map-design-info/image-1.jpg',
				},
				{
					id: helper.hash,
					src: '/assets/img/res/map-design-info/image-1.jpg',
				},
			],
		}
	},
	getters: {
		// selectCountData(){
		// 	const { type, list } = this.selectTableData;
		// 	let total = this.gridPaginationData?.total || 0;
		// 	let count = 0;

		// 	if(type == 'part'){
		// 		count = list.length;
		// 	}
		// 	else if(type == 'all'){
		// 		count = total;
		// 	}

		// 	return { count, total };
		// },
		employ(){
			const { fakeDesignInfoData } = useMapDesignStore();
			const { digitalOccupancy, staticOccupancy } = fakeDesignInfoData;

			return { digitalOccupancy, staticOccupancy }
		}
	},
	actions: {
		changeSelectionTable(selectionList){
			this.selectTableData = selectionList;
		},

		buildGridTableData(data){
			if(!data) return;

			const mapStore = useMapDesignStore();
			const list = [];

			for(let item of data){
				const detailDesignData = mapStore.buildDetailDesignData(item);
				const obj = {
					...detailDesignData,
					checked: false,
					get curSideData(){
						const { sideSelectData, sides } = this;
						return sides.find(item=>item.id==sideSelectData.value);
					}
				}

				list.push(obj);
			}

			console.log('buildGridData', list);
			return list;
		},

		async getDesignGridData({data, params}={}){
			const filterStore = useDesignFilterStore();

			// Установка дефолтных параметров
			if(!data){
				const { getSubmitFilterData } = filterStore;
				data = getSubmitFilterData();
			}

			// Если данные фильтров одинаковые, то не запрашивать данные датагрида.
			if(this.paramGridData){
				const jsonOldParam = JSON.stringify(this.paramGridData);
				const jsonCurParam = JSON.stringify({ data, params: { ...params } });

				if(jsonOldParam == jsonCurParam) return;
			}
			this.paramGridData = { data, params: { ...params } };


			this.isLoaderGrid = true;
			try {
				const res = await api.postData({ model: 'grid-data', data,
					params: {
						// per_page: '20',
						...params,
					}
				});
				console.log('getDesignGridData', res);

				if(res?.data) this.gridTableData = this.buildGridTableData(res.data);
				if(res?.meta) this.gridPaginationData = res.meta;
				if(res?.total) filterStore.totalFilters = res.total;

				this.isLoaderGrid = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isLoaderGrid = false;
			return;
		},

		async updateStructureRow({ structureIds }){
			const mapDesignStore = useMapDesignStore();
			const { detailDesignData } = mapDesignStore;

			const { getSubmitFilterData } = useDesignFilterStore();
			const filtersData = getSubmitFilterData();
			filtersData['structureIndex'] = structureIds;

			try {
				const res = await api.postData({
					model: 'grid-data',
					data: filtersData,
				});

				if(res?.data){
					const structuresList = this.buildGridTableData(res.data);

					const structureMap = new Map();
					for(const item of structuresList){
						structureMap.set(item.id, item);
					}

					const updateStructureMap = new Map();
					for(const [indexRow, item] of Object.entries(this.gridTableData)){
						if(structureMap.has(item.id)) updateStructureMap.set(item.id, indexRow);
					}

					// Обновление строк таблицы
					for( const [id, indexRow] of updateStructureMap.entries() ){
						const structure = structureMap.get(id);
						this.gridTableData[indexRow] = structure;
						console.log({indexRow, id, structure});
					}

					// Обновление карточки РК в сайдбаре
					if(detailDesignData?.id && structureMap.has(detailDesignData.id)){
						const structure = structureMap.get(detailDesignData.id);
						const oldSideValue = detailDesignData?.sideSelectData?.value;
						const { sideSelectData } = structure;

						if(oldSideValue && oldSideValue != sideSelectData.value) sideSelectData.value = oldSideValue; // установка ранее выбранной стороны
						mapDesignStore.detailDesignData = structure;
					}

				}

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		}
	},
});