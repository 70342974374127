import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notify-popup__header"
}
const _hoisted_2 = {
  key: 1,
  class: "notify-popup__header"
}
const _hoisted_3 = { class: "notify-popup__body" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "notify-popup__body__select" }
const _hoisted_11 = { class: "notify-popup__body__select" }
const _hoisted_12 = { class: "notify-popup__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "notify-popup",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.isVisible) = $event)),
    width: "480",
    "align-center": ""
  }, {
    header: _withCtx(() => [
      (_ctx.type === 'approve')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SvgIcon, {
              class: "check-green",
              name: "12-circle-check-filled"
            }),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, "Согласовать", -1 /* HOISTED */))
          ]))
        : (_ctx.type === 'deny')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SvgIcon, {
                class: "check-red",
                name: "circle-close-filled"
              }),
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "Отказать в согласовании", -1 /* HOISTED */))
            ]))
          : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.type === 'approve')
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _cache[6] || (_cache[6] = _createTextVNode("Cогласовать")),
              _createElementVNode("a", { href: _ctx.link }, _toDisplayString(_ctx.strokesLength || '0') + " ", 9 /* TEXT, PROPS */, _hoisted_5),
              _createTextVNode(_toDisplayString(_ctx.strokes) + " в", 1 /* TEXT */),
              _createElementVNode("a", { href: _ctx.link }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_6)
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.type === 'deny')
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
              _cache[7] || (_cache[7] = _createTextVNode("Отказать в согласовании")),
              _createElementVNode("a", { href: _ctx.link }, _toDisplayString(_ctx.strokesLength || '0') + " ", 9 /* TEXT, PROPS */, _hoisted_8),
              _createTextVNode(_toDisplayString(_ctx.strokes) + " в", 1 /* TEXT */),
              _createElementVNode("a", { href: _ctx.link }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_9)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_10, [
          _cache[8] || (_cache[8] = _createElementVNode("p", null, "Типы согласований", -1 /* HOISTED */)),
          _createVNode(_component_BsSingleSelect, {
            placeholder: "Выберите из списка",
            modelValue: _ctx.approvalType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.approvalType) = $event)),
            options: _ctx.approvalOptionsList,
            clearable: true,
            filterable: true,
            onChange: _ctx.changeFilterTemplate,
            onRemove: _ctx.removeTemplate
          }, null, 8 /* PROPS */, ["modelValue", "options", "onChange", "onRemove"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[9] || (_cache[9] = _createElementVNode("p", null, "Выберите месяцы", -1 /* HOISTED */)),
          _createVNode(_component_BsSelectV2, {
            modelValue: _ctx.yearMonth,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.yearMonth) = $event)),
            options: _ctx.detailMonthSelectData.options,
            clearable: true,
            filterable: true,
            size: "large"
          }, null, 8 /* PROPS */, ["modelValue", "options"])
        ]),
        _createVNode(_component_BsInput, {
          class: "target-agr-panel__comment",
          modelValue: _ctx.comment,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.comment) = $event)),
          rows: 4,
          type: "textarea",
          placeholder: "Введите комментарий"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_BsBtn, {
          size: "small",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_BsBtn, {
          type: "primary",
          size: "small",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Да")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}