import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { useDetailTargetProgStore } from '@/app/stores/target-programs/detail-target-prog-store';

import dayjs from 'dayjs';

import mixinNotifMessage from '../mixins/mixin-notif-message';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import NotifUserInfo from '@/app/components/notification/other/notif-user-info/NotifUserInfo.vue';

export default defineComponent({
	name: 'NotifMessageManager',
	mixins: [mixinNotifMessage],
	components: {
		BsBtn, NotifUserInfo,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({
				id: '',
				targetProgramId: '',
				createAt: '',
				senderType: '',
				notifType: '',
				isRead: true,
				message: '',
				user: { },
				colorData: { },
			}),
		},
		index: {
			type: Number,
			required: true
		}
	},
	data() {
		return {}
	},
	methods: {
		...mapActions(useDetailTargetProgStore, [ 'sendMassAgreementDecisionTProg' ]),
		redirect(url){
			window.location.href = url;
		},
		showApprovePopup(type){
			this.$emit('showApprovePopup', {index: this.index, type: type})
		},
	},
});