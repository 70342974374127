import { defineComponent } from 'vue';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useGridDesignStore } from '@/app/stores/structures/grid-design-store';
import { useDesignFilterStore } from '@/app/stores/structures/design-filter-store';

import cellMixin from "@/app/mixins/table/cell-mixin";

import EmployInfo from '@/app/components/map/map-design-info/employ-info/EmployInfo.vue';
import EmployCalendar from '@/app/components/map/map-design-info/employ-calendar/EmployCalendar.vue';
import EmployDatepicker from '@/app/components/datepickers/employ-datepicker/EmployDatepicker.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';

export default defineComponent({
	name: 'grid-employ-info',
	mixins: [ cellMixin ],
	components: {
		EmployInfo, BsInput, BsMultiSelect,
		EmployCalendar, BsSingleSelect, EmployDatepicker,
	},
	props: {
		// data: Object,
	},
	data() {
		return {
			// digitalVideoLength: '',
			selectedMonth: '',
		}
	},
	computed: {
		// ...mapState(useDesignFilterStore, ['curPeriodData']),
		...mapState(useGridDesignStore, ['employ']),

		period(){
			return this.rowData?.period;
		},
		sideData(){
			return this.rowData?.curSideData || {};
		},
		calendar(){
			return this.sideData?.[this.selectedMonth]; // Нет календаря
		}
	},
	methods: {
		changeMonth(date){
			if(this.selectedMonth == date) this.selectedMonth = '';
			else this.selectedMonth = date;
			// console.log(date);
		},
	},
	created() {
		// console.log(this.data);
	},
});