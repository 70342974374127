import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-auth" }
const _hoisted_2 = { class: "footer-auth__wrap" }
const _hoisted_3 = { class: "footer-auth__item" }
const _hoisted_4 = {
  class: "footer-auth__link",
  href: "mailto:support@outplan.ru"
}
const _hoisted_5 = { class: "footer-auth__link-icon" }
const _hoisted_6 = { class: "footer-auth__item" }
const _hoisted_7 = {
  class: "footer-auth__link",
  href: "#"
}
const _hoisted_8 = { class: "footer-auth__link-icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SvgIcon, { name: "18-global" })
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "footer-auth__link-label" }, "support@outplan.ru", -1 /* HOISTED */))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_SvgIcon, { name: "18-telegram" })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "footer-auth__link-label" }, "rim_support", -1 /* HOISTED */))
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "footer-auth__item" }, [
        _createElementVNode("div", { class: "footer-auth__copy" }, "(С) 2024 МГ РИМ")
      ], -1 /* HOISTED */))
    ])
  ]))
}