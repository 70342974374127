import { defineStore } from 'pinia';
import { useRootStore } from '../root-store';
import { useDesignFilterStore } from './design-filter-store';
import router from '@/app/router';

import dayjs from 'dayjs';

import helper from '@/app/utils/helper';
import common from '@/app/utils/common';
import api from '@/app/utils/service-api';
import errorService from '@/app/utils/error-service';

import fakeDesignInfoData from '../fake-data/design-store/fake-design-info-data';

export const useMapDesignStore = defineStore('mapDesignStore', {
	state() {
		return {
			isLoaderMap: true,

			markerList: [],
			placemarkCollection: [],
			searchMarker: null,
			// designsData: [],
			clusterList: [],
			infoApData: null,
			detailDesignData: null,
			checkMapMarkerView: false,
			checkMarkerInTarget: false,
			isSetBounds: true,
			isSetPositionMap: false,
			coordsCenterMap: null,
			zoomMap: null,
			filterMapData: null,


			typeDesignIcon: {
				'другое': 'type-other',
				'пиллар': 'type-cityformat',
				'cити-формат': 'type-cityformat',
				'ситиборд': 'type-cityboard',
				// 'биллборд': 'type-billboard', //
				// 'юнипол': 'type-unipol', //
				// 'суперборд': 'type-superboard', //
				// '': '',
				'суперсайт': 'type-supersite',
				'арка': 'type-arch',
				'щит': 'type-screen',
				'тумба': 'type-table',
				'медиафасад': 'type-media-facade',
				'остановки': 'type-halt-2',
				'индор': 'type-indore',
			},

			fakeDesignInfoData,
		}
	},

	getters: {
		markerMap(state){
			let map = {};
			for( let item of state.markerList ){
				map[item.code] = item;
			}
			return map;
		},

	},
	actions: {
		getTypeDesignIcon(name){
			if(name) for( let [key, value] of Object.entries(this.typeDesignIcon)){
				let strName = name.toLowerCase();
				let strKey = key.toLowerCase();

				if(strName.includes(strKey)) return value;
			}

			return this.typeDesignIcon['другое'];
		},

		generateInfoApData(){
			let data = {
				months: [],
			}
			let sideCollection = [];

			let constructType = ['Медиафасад', 'Остановки', 'Щит', 'Панель-кронштейн', 'Пиллар', 'Ситиборд'];
			let size = ['7,68х3,84', '6х3', '8х4', '8х4,8', '15х5', '1,2х1,8'];
			let mech = ['Призма', 'Скроллер', 'Статика', 'Digital'];
			let direct = ['A', 'B', 'C'];

			for(let i of new Array(35)){
				sideCollection.push({
					id: helper.hash,
					constructType: helper.rndItem(constructType),
					size: helper.rndItem(size),
					mech: helper.rndItem(mech),
					direct: helper.rndItem(direct),
				});
			}

			for(let i=0; i<7; i++){
				let month = {
					date: dayjs('2024-01-01').add(i, 'months').format('YYYY-MM'),
					sideList: [],
				}

				for(let i of new Array(helper.rndNum(3,5))){
					month.sideList.push(helper.getQueueItem(sideCollection))
				}

				data.months.push(month);
			}

			// console.log('generateInfoApData', data);
			this.infoApData = data;
		},

		/** Получение информации статусов занятости для маркера */
		getMarkerStatusInfo(sides){
			const statusMarker = {
				free: false,
				reserve: false,
				busy: false,
			};
			const statusSidesMap = new Map();
			const filterStore = useDesignFilterStore();
			const { from, to } = filterStore.placePeriodDate;
			let countMonth = dayjs(to).diff(from, 'month', true);
			countMonth = Math.ceil(countMonth) || 1;

			for( let i=0; i<countMonth; i++ ){
				const formatDate = dayjs(from).add(i, 'months').format('YYYY-MM');

				if(sides?.length) for(const item of sides){
					const { isDigital, occupancy, id, inTargetProgram } = item;
					let statusSide = statusSidesMap.get(id);
					if(!statusSide) statusSide = {
						free: false,
						reserve: false,
						busy: false,
						unavailable: false,
						partSold: false,
						added: inTargetProgram,
					}
					// if(inTargetProgram) console.log(item);

					const { free, reserve, busy } = statusSide;
					if( free && reserve && busy ) break;

					const monthData = occupancy?.[formatDate];
					if(!monthData){
						statusSidesMap.set(id, statusSide);
						continue;
					}

					/* Возможно когда-то логика расчета диджитального градусника пригодится */
					// if(isDigital){
					// 	const { free, reserved, busy } = monthData;
					// 	const { outFrequency, digitalVideoLength, blockDuration, slotDuration } = filterStore;
					// 	let duration = 5;
					// 	let frequency = 1;
					// 	let block = item?.block || 60;
					// 	let slot = item?.slot || 5;

					// 	if(digitalVideoLength) duration = Number(digitalVideoLength);
					// 	if(outFrequency?.value) frequency = Number(outFrequency.value);

					// 	const countOutHour = 60*60 / block; // Количество выходов в час стандартного хроно, равного слоту
					// 	const timeFree = free * slot; // свободно в часе
					// 	const timeReserved = reserved * slot; // зарезервировано в часе

					// 	// const totalTime = timeFree + timeReserved;
					// 	const personTime = Math.ceil(duration * (frequency * countOutHour)); // Сколько нужно пользователю

					// 	if(free && timeFree >= personTime) statusSide.free = true;
					// 	else if( reserved && timeReserved >= personTime ) statusSide.reserve = true;
					// 	else statusSide.busy = true;
					// } else {
					// 	if(monthData?.status == 'free') statusSide.free = true;
					// 	else if(monthData?.status == 'reserved') statusSide.reserve = true;
					// 	else if(monthData?.status == 'busy') statusSide.busy = true;
					// }

					if(monthData?.status == 'free') statusSide.free = true;
					if(monthData?.status == 'reserved') statusSide.reserve = true;
					if(monthData?.status == 'busy') statusSide.busy = true;
					if(monthData?.status == 'mixed') statusSide.partSold = true;

					if(monthData?.unavailable) statusSide.unavailable = true;

					statusSidesMap.set(id, statusSide);
				}
			}

			const statusSides = [];
			for( const [id, item] of statusSidesMap.entries() ){
				const { free, reserve, busy } = item
				statusSides.push({ id, ...item });

				statusMarker.free = statusMarker.free || free;
				statusMarker.reserve = statusMarker.reserve || reserve;
				statusMarker.busy = statusMarker.busy || busy;
			}

			return {
				marker: statusMarker,
				sides: statusSides,
				statusSidesMap,
			};
		},

		async createPlacemark(marker){
			const rootStore = useRootStore();
			const ymaps = await common.ymaps;
			const placemarkContainer = ymaps.templateLayoutFactory.createClass(`<div id="marker-${marker.code}"></div>`);

			let widthMarker = 48;
			let coordShape = [0, -35];
			if(rootStore.markerType == 2){
				widthMarker = 35;
				coordShape = [0, 0];
				// console.log({marker});
			}

			const placemark = new ymaps.Placemark(marker.coords, {name: `marker-${marker.code}`}, {
				iconLayout: placemarkContainer,
				iconShape: {type: 'Circle', coordinates: coordShape, radius: widthMarker/2},
			});
			placemark['markerCode'] = marker.code;

			return placemark;
		},

		/** Форматирование данных маркеров для карты */
		async buildMapData(data){
			if(!data) return;
			const placemarkCollection = [];
			const markerList = [];
			const nullCoordMarkerList = [];
			// let count = 0;

			console.time('buildMapData');
			for( let item of data ){
				const { id, gid, latitude, longitude, sides } = item;
				if( !Number(latitude) || !Number(longitude)){
					nullCoordMarkerList.push(item);
					// continue; // с нулевыми координатами пропускать
				}
				const marker = {
					code: helper.hash,
					id, gid,
					coords: [ latitude, longitude ],
					type: this.getTypeDesignIcon(item?.type),
					structData: item,
					statusInfo: this.getMarkerStatusInfo(sides),
				};
				const placemark = await this.createPlacemark(marker);

				placemarkCollection.push(placemark);
				markerList.push({
					...marker,
					placemark,
				});

				// count++;
				// if(count % 50 == 0) await new Promise((resolve)=>{
				// 	// console.log(count);
				// 	requestAnimationFrame(()=>resolve());
				// });
			}
			console.timeEnd('buildMapData');

			console.log('nullCoordList', nullCoordMarkerList);
			// console.log(list);
			return { markerList, placemarkCollection };
		},

		/** Формирование данных для карточки РК */
		buildDetailDesignData(data){
			const { id, gid, address, cityId,
				latitude, longitude, contractEndDate,
				serviceStatus, type, structureCategory } = data;
			const filterStore = useDesignFilterStore();
			const sideSelectData = {
				value: '',
				options: [],
			};
			let sides = [];
			let checkMonthDate = dayjs().format('YYYY-MM');
			let selectedPeriods = null;
			let photoList = [];

			if(filterStore.curPeriodData?.from) checkMonthDate = dayjs(filterStore.curPeriodData.from).format('YYYY-MM');
			// Заполнение выбранных периодов в календаре
			if(filterStore.curPeriodData?.from && filterStore.curPeriodData?.to){
				let { from, to } = filterStore.curPeriodData;

				// TODO: Для чего-то это было сделано? Непонятно. Закомментирую
				// if(dayjs(to).date()==1) to = dayjs(to).subtract(1, 'days').format('YYYY-MM-DD');

				selectedPeriods = helper.splitIntoMonthlyPeriods(from, to);
				// console.log({selectedPeriods});
			}

			if(data?.sides?.length){
				// Опции для выбора сторон
				sideSelectData.options = data.sides.map(item=>{
					const { id, gid, sideCode, selected, lighted, is_digital, inTargetProgram, serviceStatus } = item;
					const label = sideCode;
					const { structureId, sideId } = router?.currentRoute?.value?.query ?? {};

					if(selected) sideSelectData.value = id;
					// Если в урле есть sideId, то происходит переключение на выбранную сторону в РК и датагриде
					if(Number(structureId)==data.id && sideId && Number(sideId) != id) sideSelectData.value = sideId;

					return {
						value: id,
						label,
						inTargetProgram,
						isWorking: serviceStatus?.toLowerCase() === 'работает',
						light: !!lighted,
						isDigital: is_digital,
					};
				});
				if(!sideSelectData.value) sideSelectData.value = sideSelectData.options[0].value;

				sides = data.sides.map(item=>{
					const { gid, isVAT,
						priceMonthNotVAT, pricePeriodNotVAT,
						GRP, OTS, size, type, netType,
						is_digital, blockDuration, slotDuration,
					} = item;
					// let { occupancy } = item;
					let digitalData = null;
					let digitalDuration = {
						value: '',
						options: [],
					};
					let outFrequency = {
						value: '',
						options: [],
					};

					// if(occupancy && Array.isArray(occupancy) && occupancy.length == 0) occupancy = {};
					if(item.photoList?.length) photoList.push(...item.photoList);

					if(is_digital){
						const { frequency, digitalVideoLength } = filterStore;

						digitalData = { blockDuration, slotDuration };
						if(blockDuration && slotDuration){
							digitalDuration = common.createDurationOptions({ slotDuration, blockDuration });

							const label = digitalDuration.map?.[digitalVideoLength];
							if(label) digitalDuration.value = Number(digitalVideoLength);
							// console.log({digitalDuration});
						}
						if(blockDuration){
							outFrequency = common.createOutFrequencyOptions({ blockDuration });

							const label = outFrequency.map?.[frequency.value];
							if(label) outFrequency.value = frequency.value;
							// console.log({ outFrequency });
						}
					}

					return {
						...item,

						// occupancy,
						// queueData: this.createSideQueueData(occupancy),
						digitalData,
						direction: gid.split('-').at(-1),
						priceMonth: priceMonthNotVAT,
						pricePeriod: pricePeriodNotVAT,
						vat: isVAT? 'Да': 'Нет',
						grp: GRP,
						ots: OTS,
						selectedPeriods,
						sideSize: size,
						network: netType,
						sideMech: helper.upFirstLetter(type),
						digitalDuration, outFrequency,
					}
				});
			}

			const detailDesignData = {
				id, gid, cityId, address, serviceStatus,
				structureCategory,
				sideSelectData,
				coords: [ latitude, longitude ],
				constructType: type,
				period: filterStore.curPeriodData,
				checkMonthDate,
				sides, photoList,
				contractEndDate,
			};

			return detailDesignData;
		},

		/**
		 * Получение данных карты
		 * @param {*} data - данные выбранных фильтров
		 */
		async getMapData(data){
			const filterStore = useDesignFilterStore();

			// Установка дефолтных параметров
			if(!data){
				const { getSubmitFilterData } = filterStore;
				data = getSubmitFilterData();
			}

			// Если данные фильтров одинаковые, то не запрашивать данные маркеров.
			if(this.filterMapData){
				const jsonOldFilter = JSON.stringify(this.filterMapData);
				const jsonCurFilter = JSON.stringify(data);
				if(jsonOldFilter == jsonCurFilter){
					this.isSetPositionMap = true;
					return;
				}
			}
			this.filterMapData = data;


			this.isSetPositionMap = false;
			this.isLoaderMap = true;
			try {
				const res = await api.postData({ model: 'map', data});
				console.log('getMapData', res);

				if(res?.data){
					const { markerList, placemarkCollection } = await this.buildMapData(res.data);
					this.markerList = markerList;
					this.placemarkCollection = placemarkCollection;
				}
				if(res?.total) filterStore.totalFilters = res.total;

				this.isLoaderMap = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isLoaderMap = false;
			return;
		},
		async updateMapMarkers({ structureIds }){
			const { getSubmitFilterData } = useDesignFilterStore();
			const filtersData = getSubmitFilterData();
			filtersData['structureIndex'] = structureIds;

			try {
				const res = await api.postData({
					model: 'map',
					data: filtersData,
				});

				if(res?.data){
					const { markerList } = await this.buildMapData(res.data);

					const markerMap = new Map();
					for( const item of markerList ){
						markerMap.set(item.id, item);
					}

					const updateMarkerList = this.markerList.filter(item=>markerMap.has(item.id));
					// console.log({ res, markerList });
					for( const updateMarker of updateMarkerList ){
						const marker = markerMap.get(updateMarker.id);
						const { statusInfo, structData } = marker;
						updateMarker['statusInfo'] = statusInfo;
						updateMarker['structData'] = structData;
					}
				}

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},


		/**
		 * Получение детальных данных по одной РК
		 * @param {Number} id - id РК
		 * @param {Boolean} noDataMap - получить данные без запроса по маркерам карты
		 */
		async getStructure({id, selectSideId, noDataMap, filter, isMutableStore=true}){
			const filterStore = useDesignFilterStore();

			if(filter) for(let [ key, value ] of Object.entries(filter)){
				if(filterStore?.[key]){
					if(Array.isArray(value)) filterStore[key].value = value.map(item=>Number(item));
					else filterStore[key] = value;
				}
			}

			const data = filterStore.getSubmitFilterData();
			try {
				const resStruct = await api.postDataItem({ model: 'structure', id, data });
				console.log('getStructure', resStruct);

				if(isMutableStore && resStruct?.data){
					this.detailDesignData = this.buildDetailDesignData(resStruct.data);
					if(selectSideId){
						const { sideSelectData } = this.detailDesignData;
						sideSelectData.value = selectSideId;
					}

					if(!noDataMap){
						const resMap = await this.getMapData(data);

						// Если нужно отрисовать один маркер на карте
						// const resMarker = await this.getMapMarkerItem(id, data);
						// this.buildMapData([resMarker.data]);
					}
				}
				console.log('detailDesignData', this.detailDesignData);
				return resStruct;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async getPopupStructure(id){
			if(!id) return;
			const filterStore = useDesignFilterStore();
			const filters = filterStore.getSubmitFilterData();

			try {
				const res = await api.postData({
					model: `structure/popup/${id}`,
					data: { filters }
				});

				console.log(res);
				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		}
	},
});